import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("dx")) || 0,
}

export const dxSlice = createSlice({
  name: 'dx',
  initialState,
  reducers: {
        add: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("dx", state.value);
        },
        change : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("dx", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { add, change } = dxSlice.actions

export default dxSlice.reducer