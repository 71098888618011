import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("objp") || "",
}

export const objpSlice = createSlice({
  name: 'objp',
  initialState,
  reducers: {
        addp: (state, action) => {
          const point = action.payload;
          state.value  += point;
          localStorage.setItem("objpp", state.value);
        },
        changep : (state, action) => {
          state.value = action.payload;
          localStorage.setItem("objpp", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addp, changep } = objpSlice.actions

export default objpSlice.reducer