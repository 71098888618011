import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("xslider")) || 0,
}

export const xsliderSlice = createSlice({
  name: 'xslider',
  initialState,
  reducers: {
        addxs: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("xslider", state.value);
        },
        changexs : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("xslider", action.payload);
        },
  },
})

const { actions, reducer } = xsliderSlice;

export const { addxs, changexs } = actions;

export default reducer