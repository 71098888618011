import React, {useState,useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add,change } from '../features/dxSlice';
import { switcher } from '../features/etatSlice';
import { modifier } from '../features/xInitSlice';
import { addy,changey } from '../features/dypSlice';
import { changeVitesse } from '../features/vitesseSlice';

const Fin = ({x,y}) => {

    const dispatch = useDispatch();

    const vitesse = useSelector((state) => state.vitesse.value);

    const [indexSelected, setindexSelected] = useState(0)

    const [listVitesse, setlistVitesse] = useState([
        "× 1,5",
        "× 1",
        "× 0,5",
    ])

    useEffect(() => {
        setindexSelected(listVitesse.indexOf(vitesse));
    }, [])
    

    const [open, setopen] = useState(false)

    const handleClick = (e,index) => {
        console.log(`index : ${index}`);
        setindexSelected(index);
        dispatch(changeVitesse(listVitesse[index]));
        setopen(false);
    }

    const handleClickOpen = (e) => {
        setopen(!open);
    }
    
    return ( 
        <Fragment>
            <rect x={x} y={y} rx="10" ry="10" width="150" height="30" fill="#291719"/>
            <rect x={x+85} y={y+5} rx="7" ry="7" width="60" height="20" fill="#F5F5F5" style={{ cursor : "pointer"}} onPointerDown={handleClickOpen}/>
            <text x={x+15} y={y+20}   className="medium">Vitesse</text>
            <text x={x+114} y={y+21}  textAnchor="middle" className="small2" style={{ cursor : "pointer"}} onPointerDown={handleClickOpen}>{listVitesse[indexSelected]}</text>
            {
              open === true && (
                <Fragment >
                    <rect x={x+85} y={y-75} rx="7" ry="7" width="60" height="75" fill="#ffffff" style={{ cursor : "pointer"}}/>
                    {
                        listVitesse.map( (elem,index) => {
                            return (
                                <Fragment key={`vit${index}`}>
                                    <rect x={x+85} y={`${y-75+index*26}`} rx="7" ry="7" fill={ indexSelected !== index ? "#f5f5f5" : "#C4573F"} width="60" height="23" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                    {
                                        index !== 2 && (
                                            <rect x={x+85} y={`${y-67+index*26}`} fill={ indexSelected !== index ? "#f5f5f5" : "#C4573F"} width="60" height="15" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                        )
                                    }
                                    {
                                        index !== 0 && (
                                            <rect x={x+85} y={`${y-75+index*26}`} fill={ indexSelected !== index ? "#f5f5f5" : "#C4573F"} width="60" height="15" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                        )
                                    }
                                    <text x={x+114} y={`${y-57+index*26}`} className="small2" textAnchor="middle" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}>{elem}</text>
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
              )
            }
            
        </Fragment>
     )
}
 
export default Fin;