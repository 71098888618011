import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('xInit')) || -300,
}

export const xInitSlice = createSlice({
  name: 'xInit',
  initialState,
  reducers: {
    modifier: (state,action) => {
      if(action.payload === "gauche"){
        state.value = -300;
      }else if(action.payload === "droite"){
        state.value = 330;
      }else{
        state.value = -300;
      }
      localStorage.setItem("xInit", state.value);
    },
  },
})

// Action creators are generated for each case reducer function
export const { modifier } = xInitSlice.actions

export default xInitSlice.reducer