import React, { Fragment, useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addy,changey } from '../features/dypSlice';
import { add,change } from '../features/dxSlice';
import { changeRef } from '../features/refSlice';

const RouUn = ({y,width}) => {

    const dy = useSelector((state) => state.dyp.value);
    const dx = useSelector((state) => state.dx.value);
    const ref = useSelector((state) => state.ref.value);

    const decalx = useSelector((state) => state.decalx.value);
    const decaly = useSelector((state) => state.decaly.value);

    const sx = useSelector((state) => state.sx.value);
    const sy = useSelector((state) => state.sy.value);

    const [rot, setrot] = useState(0)
    
    useEffect(() => {
        setrot(dx*180/(Math.PI*129.4));
    }, [dx])
    
    const getMatrixForRotation = (a, cx, cy) =>
        {
            var ca = Math.cos(a * Math.PI / 180);
            var sa = Math.sin(a * Math.PI / 180);
    
            var a = ca.toFixed(4);
            var b = sa.toFixed(4);
            var c = (-sa).toFixed(4);
            var d = ca.toFixed(4);
            var e = (-ca * cx + sa * cy + cx).toFixed(4);
            var f = (-sa * cx - ca * cy + cy).toFixed(4);
    
            return "matrix(" + [a,b,c,d,e,f].join(' ') + ")";
        }

    return ( 
        <svg
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 5000 1917"
        preserveAspectRatio="xMinYMin meet"
        width={width}
        y={y+decaly-sy}
        x={dx+decalx-sx}
        >
            <g transform ={getMatrixForRotation(rot,2694.2,1473.6)}>
            <path
            fill="#48546b"
            d="M2572.75 1483.34v-19.566h97.856l-69.193-69.215 13.828-13.832 69.193 69.219v-97.891h19.555v97.891l69.192-69.219 13.829 13.832-69.198 69.215h97.855v19.566h-97.855l69.198 69.214-13.829 13.835-69.192-69.222v97.893h-19.555v-97.893l-69.198 69.222-13.823-13.835 69.193-69.214h-97.856"
            ></path>
            <path
                fill="#2e384d"
                d="M2694.21 1371.2c-56.513 0-102.328 45.826-102.328 102.352s45.815 102.347 102.328 102.347c56.512 0 102.333-45.821 102.333-102.347 0-56.526-45.821-102.352-102.333-102.352zm0-27.05c71.453 0 129.375 57.937 129.375 129.402 0 71.465-57.922 129.402-129.375 129.402-71.454 0-129.375-57.937-129.375-129.402 0-71.465 57.921-129.402 129.375-129.402"
            ></path>
            <path
                fill="#939fb8"
                d="M2694.21 1510.11c20.078 0 36.458-16.421 36.458-36.555 0-20.132-16.38-36.555-36.458-36.555-20.079 0-36.459 16.423-36.459 36.555 0 20.134 16.38 36.555 36.459 36.555"
            ></path>
            <path
                fill="#2e384d"
                d="M2694.21 1491.01c9.638 0 17.5-7.839 17.5-17.45 0-9.611-7.862-17.45-17.5-17.45s-17.5 7.839-17.5 17.45c0 9.611 7.862 17.45 17.5 17.45"
            ></path>
            </g>
        
        </svg>
     );
}
 
export default RouUn;