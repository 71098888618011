import React, { Fragment,useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addopt, changeopt } from '../features/objptSlice';
import { addpp, changepp } from '../features/objppSlice';
import { addp, changep } from '../features/objpSlice';
import { addpr, changepr } from '../features/objprSlice';
import { addlv, changelv } from '../features/objlvSlice';
import { addw, changew } from '../features/objwSlice';
import { changeTraj } from '../features/trajSlice';

const Trajectoire = () => {

    const dispatch = useDispatch();

    const [x, setx] = useState(0)
    const [y, sety] = useState(0)

    const [decaltrajY, setdecaltrajY] = useState(0)

    const [i, seti] = useState(0)

    const traj = useSelector((state) => state.traj.value);
    const decalx = useSelector((state) => state.decalx.value);
    const decaly = useSelector((state) => state.decaly.value);
    const sx = useSelector((state) => state.sx.value);
    const sy = useSelector((state) => state.sy.value);
    const dx = useSelector((state) => state.dx.value);
    const ref = useSelector((state) => state.ref.value);
    const xInit = useSelector((state) => state.xInit.value);
    const dy = useSelector((state) => state.dyp.value);

    const objpt = useSelector((state) => state.objpt.value);
    const objpp = useSelector((state) => state.objpp.value);

    const xslider = useSelector((state) => state.xslider.value);
    const yslider = useSelector((state) => state.yslider.value);

    const etat = useSelector((state) => state.etat.value);


    const [trajPierre, settrajPierre] = useState("");
    const [trajPioche, settrajPioche] = useState("");
    const [trajLanterne, settrajLanterne] = useState("");
    const [trajPoint, settrajPoint] = useState("");
    const [trajWagonnet, settrajWagonnet] = useState("");
    const [trajPelle, settrajPelle] = useState("");


    useEffect(() => {
      settrajPierre("");
      settrajPioche("");
      settrajLanterne("");
      settrajPoint("");
      settrajWagonnet("");
      settrajPelle("");

      if(ref === "Pelle"){


        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


          
        settrajLanterne("M 3975 910 L3975 910");

    }else if(ref === "Lanterne verte"){

        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


        settrajPelle("M 750 1330 L 750 1330");

    }else if(ref === "Sol de la mine" || ref === "Corde" ){
        

        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


          
        settrajLanterne("M 3975 910 L3975 910");
        settrajPelle("M 750 1330 L 750 1330");


    }else if(ref === "Pierre qui tombe"){

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        let decalagey = dy*4;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;


        if (xInit === 330 && dx >= 328){
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
        }else if(xInit === 330){
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2-decalagey} L ${xpp2+(dx-330)*4} ${ypp2-decalagey}`);
            settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
            settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
        }else if(xInit === -300){
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp-decalagey} L ${xpp+(dx+300)*4} ${ypp-decalagey}`);
            settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
            settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
        }


    }else if(ref === "Wagonnet"){

        // PIERRE QUI TOMBE

        //GAUCHE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        // PIOCHE

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;

        

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
            settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
            settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
        }

        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
            settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
        }            


    }else if(ref === "Pioche"){

        // PIERRE QUI TOMBE

        //GAUCHE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
            settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
            settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
        }

        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
            settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
        }       

    }

    dispatch(changeopt(trajPierre));
    dispatch(changepp(trajPioche));
    dispatch(changep(trajPelle));
    dispatch(changelv(trajLanterne));
    dispatch(changepr(trajPoint));
    dispatch(changew(trajWagonnet));
    }, [])

    useEffect(() => {
        settrajPierre("");
        settrajPioche("");
        settrajLanterne("");
        settrajPoint("");
        settrajWagonnet("");
        settrajPelle("");
  
        if(ref === "Pelle"){
  
  
          // PIERRE QUI TOMBE
          let x0 = 2780;
          let y0 = 200;
  
          let t = trajPierre;
  
          // WAGONNET
  
          //GAUCHE
          let x02 = 1800;
          let y02 = 1200;
  
          //DROITE
          let x022 = 4330;
          let y022 = 1200;
  
          let t2 = trajWagonnet;
  
          // PIOCHE
  
          //GAUCHE
          let x03 = 2465;
          let y03 = 855;
  
          //DROITE
          let x033 = 4995;
          let y033 = 855;
  
          let t3 = trajPioche;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
  
          if (xInit === 330 && dx >= 328){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
          }else if(xInit === 330){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
          }
  
          if (xInit === -300 && dx <= -298){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
          }else if(xInit === -300){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
          }
  
  
            
          settrajLanterne("M 3975 910 L3975 910");
  
      }else if(ref === "Lanterne verte"){
  
          // PIERRE QUI TOMBE
          let x0 = 2780;
          let y0 = 200;
  
          let t = trajPierre;
  
          // WAGONNET
  
          //GAUCHE
          let x02 = 1800;
          let y02 = 1200;
  
          //DROITE
          let x022 = 4330;
          let y022 = 1200;
  
          let t2 = trajWagonnet;
  
          // PIOCHE
  
          //GAUCHE
          let x03 = 2465;
          let y03 = 855;
  
          //DROITE
          let x033 = 4995;
          let y033 = 855;
  
          let t3 = trajPioche;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
  
          if (xInit === 330 && dx >= 328){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
          }else if(xInit === 330){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
          }
  
          if (xInit === -300 && dx <= -298){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
          }else if(xInit === -300){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
          }
  
  
          settrajPelle("M 750 1330 L 750 1330");
  
      }else if(ref === "Sol de la mine" || ref === "Corde" ){
          
  
          // PIERRE QUI TOMBE
          let x0 = 2780;
          let y0 = 200;
  
          let t = trajPierre;
  
          // WAGONNET
  
          //GAUCHE
          let x02 = 1800;
          let y02 = 1200;
  
          //DROITE
          let x022 = 4330;
          let y022 = 1200;
  
          let t2 = trajWagonnet;
  
          // PIOCHE
  
          //GAUCHE
          let x03 = 2465;
          let y03 = 855;
  
          //DROITE
          let x033 = 4995;
          let y033 = 855;
  
          let t3 = trajPioche;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
  
          if (xInit === 330 && dx >= 328){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
          }else if(xInit === 330){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
          }
  
          if (xInit === -300 && dx <= -298){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
          }else if(xInit === -300){
              settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
              settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
          }
  
  
            
          settrajLanterne("M 3975 910 L3975 910");
          settrajPelle("M 750 1330 L 750 1330");
  
  
      }else if(ref === "Pierre qui tombe"){
  
          // WAGONNET
  
          //GAUCHE
          let x02 = 1800;
          let y02 = 1200;
  
          //DROITE
          let x022 = 4330;
          let y022 = 1200;
  
          let t2 = trajWagonnet;
  
          // PIOCHE
  
          //GAUCHE
          let x03 = 2465;
          let y03 = 855;
  
          //DROITE
          let x033 = 4995;
          let y033 = 855;
  
          let t3 = trajPioche;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
          let decalagey = dy*4;
  
          //LANTERNE
  
          let x05 = 3975;
          let y05 = 910; 
  
          let t5 = trajLanterne;
  
          //PELLE
  
          let x06 = 750;
          let y06 = 1330; 
  
          let t6 = trajPelle;
  
  
          if (xInit === 330 && dx >= 328){
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
          }else if(xInit === 330){
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
              settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2-decalagey} L ${xpp2+(dx-330)*4} ${ypp2-decalagey}`);
              settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
              settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
          }
  
          if (xInit === -300 && dx <= -298){
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
          }else if(xInit === -300){
              settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
              settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
              settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp-decalagey} L ${xpp+(dx+300)*4} ${ypp-decalagey}`);
              settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
              settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
          }
  
  
      }else if(ref === "Wagonnet"){
  
          // PIERRE QUI TOMBE
  
          //GAUCHE
          let x0 = 2780;
          let y0 = 200;
  
          let t = trajPierre;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
          // PIOCHE
  
          //DROITE
          let x033 = 4995;
          let y033 = 855;
  
          //GAUCHE
          let x03 = 2465;
          let y03 = 855;
  
          //LANTERNE
  
          let x05 = 3975;
          let y05 = 910; 
  
          let t5 = trajLanterne;
  
          //PELLE
  
          let x06 = 750;
          let y06 = 1330; 
  
          let t6 = trajPelle;
  
          
  
          if (xInit === -300 && dx <= -298){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
          }else if(xInit === -300){
              settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
              settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
              settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
              settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
              settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
          }
  
          if (xInit === 330 && dx >= 328){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
          }else if(xInit === 330){
              settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
              settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
              settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
              settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
              settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
          }            
  
  
      }else if(ref === "Pioche"){
  
          // PIERRE QUI TOMBE
  
          //GAUCHE
          let x0 = 2780;
          let y0 = 200;
  
          let t = trajPierre;
  
          //ROUE
  
          //GAUCHE
          let x04 = 2248;
          let y04 = 1497;
  
          let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;
  
          let x = 117;
          let y = 0;
          let x00 = 2131;
          let y00 = 1497;
  
          let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
          let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);
  
          let xpp = xp + 2131;
          let ypp = yp + 1497;
  
          //DROITE
          let x044 = 4765;
          let y044 = 1497;
  
          let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;
  
          //console.log(`angle : ${rot2*180/Math.PI}`);
  
          let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
          let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);
  
          let xpp2 = xp2 + 4648;
          let ypp2 = yp2 + 1497;
  
          let t4 = trajPoint;
  
          //LANTERNE
  
          let x05 = 3975;
          let y05 = 910; 
  
          let t5 = trajLanterne;
  
          //PELLE
  
          let x06 = 750;
          let y06 = 1330; 
  
          let t6 = trajPelle;
  
          // WAGONNET
  
          //GAUCHE
          let x02 = 1800;
          let y02 = 1200;
  
          //DROITE
          let x022 = 4330;
          let y022 = 1200;
  
          let t2 = trajWagonnet;
  
          if (xInit === -300 && dx <= -298){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
          }else if(xInit === -300){
              settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
              settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
              settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
              settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
              settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
          }
  
          if (xInit === 330 && dx >= 328){
              settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
              settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
              settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
          }else if(xInit === 330){
              settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
              settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
              settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
              settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
              settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
          }       
  
      }
  
      dispatch(changeopt(trajPierre));
      dispatch(changepp(trajPioche));
      dispatch(changep(trajPelle));
      dispatch(changelv(trajLanterne));
      dispatch(changepr(trajPoint));
      dispatch(changew(trajWagonnet));
      }, [xInit])

    useEffect(() => {
      if(ref === traj || (ref === "Pioche" && traj === "Pointe de la pioche")){
        dispatch(changeTraj("Rien"));
      }

      settrajPierre("");
      settrajPioche("");
      settrajLanterne("");
      settrajPoint("");
      settrajWagonnet("");
      settrajPelle("");

      if(ref === "Pelle"){


        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


          
        settrajLanterne("M 3975 910 L3975 910");

    }else if(ref === "Lanterne verte"){

        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


        settrajPelle("M 750 1330 L 750 1330");

    }else if(ref === "Sol de la mine" || ref === "Corde" ){
        

        // PIERRE QUI TOMBE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;


        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
        }


          
        settrajLanterne("M 3975 910 L3975 910");
        settrajPelle("M 750 1330 L 750 1330");


    }else if(ref === "Pierre qui tombe"){

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        // PIOCHE

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        let t3 = trajPioche;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        let decalagey = dy*4;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;


        if (xInit === 330 && dx >= 328){
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
        }else if(xInit === 330){
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
            settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2-decalagey} L ${xpp2+(dx-330)*4} ${ypp2-decalagey}`);
            settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
            settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
        }

        if (xInit === -300 && dx <= -298){
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
        }else if(xInit === -300){
            settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
            settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
            settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp-decalagey} L ${xpp+(dx+300)*4} ${ypp-decalagey}`);
            settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
            settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
        }


    }else if(ref === "Wagonnet"){

        // PIERRE QUI TOMBE

        //GAUCHE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        // PIOCHE

        //DROITE
        let x033 = 4995;
        let y033 = 855;

        //GAUCHE
        let x03 = 2465;
        let y03 = 855;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;

        

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
            settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
            settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
            settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
        }

        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
            settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
            settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
            settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
        }            


    }else if(ref === "Pioche"){

        // PIERRE QUI TOMBE

        //GAUCHE
        let x0 = 2780;
        let y0 = 200;

        let t = trajPierre;

        //ROUE

        //GAUCHE
        let x04 = 2248;
        let y04 = 1497;

        let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

        let x = 117;
        let y = 0;
        let x00 = 2131;
        let y00 = 1497;

        let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
        let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

        let xpp = xp + 2131;
        let ypp = yp + 1497;

        //DROITE
        let x044 = 4765;
        let y044 = 1497;

        let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

        //console.log(`angle : ${rot2*180/Math.PI}`);

        let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
        let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

        let xpp2 = xp2 + 4648;
        let ypp2 = yp2 + 1497;

        let t4 = trajPoint;

        //LANTERNE

        let x05 = 3975;
        let y05 = 910; 

        let t5 = trajLanterne;

        //PELLE

        let x06 = 750;
        let y06 = 1330; 

        let t6 = trajPelle;

        // WAGONNET

        //GAUCHE
        let x02 = 1800;
        let y02 = 1200;

        //DROITE
        let x022 = 4330;
        let y022 = 1200;

        let t2 = trajWagonnet;

        if (xInit === -300 && dx <= -298){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
        }else if(xInit === -300){
            settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
            settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
            settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
            settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
        }

        if (xInit === 330 && dx >= 328){
            settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
            settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
        }else if(xInit === 330){
            settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
            settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
            settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
            settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
            settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
        }       

    }

    dispatch(changeopt(trajPierre));
    dispatch(changepp(trajPioche));
    dispatch(changep(trajPelle));
    dispatch(changelv(trajLanterne));
    dispatch(changepr(trajPoint));
    dispatch(changew(trajWagonnet));
    }, [ref])
    
    useEffect(() => {
        settrajPierre("");
        settrajPioche("");
        settrajLanterne("");
        settrajPoint("");
        settrajWagonnet("");
        settrajPelle("");

        if(ref === "Pelle"){


            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


              
            settrajLanterne("M 3975 910 L3975 910");

        }else if(ref === "Lanterne verte"){

            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


            settrajPelle("M 750 1330 L 750 1330");

        }else if(ref === "Sol de la mine" || ref === "Corde" ){
            

            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


              
            settrajLanterne("M 3975 910 L3975 910");
            settrajPelle("M 750 1330 L 750 1330");


        }else if(ref === "Pierre qui tombe"){

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            let decalagey = dy*4;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;


            if (xInit === 330 && dx >= 328){
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            }else if(xInit === 330){
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2-decalagey} L ${xpp2+(dx-330)*4} ${ypp2-decalagey}`);
                settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
                settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            }else if(xInit === -300){
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp-decalagey} L ${xpp+(dx+300)*4} ${ypp-decalagey}`);
                settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
                settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
            }


        }else if(ref === "Wagonnet"){

            // PIERRE QUI TOMBE

            //GAUCHE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            // PIOCHE

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;

            

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
                settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
                settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            }

            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
                settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
            }            


        }else if(ref === "Pioche"){

            // PIERRE QUI TOMBE

            //GAUCHE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
                settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
                settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            }

            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
                settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
            }       

        }

        dispatch(changeopt(trajPierre));
        dispatch(changepp(trajPioche));
        dispatch(changep(trajPelle));
        dispatch(changelv(trajLanterne));
        dispatch(changepr(trajPoint));
        dispatch(changew(trajWagonnet));
    }, [traj])
    

    useEffect(() => {

        settrajPierre("");
        settrajPioche("");
        settrajLanterne("");
        settrajPoint("");
        settrajWagonnet("");
        settrajPelle("");

        if(ref === "Pelle"){


            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


              
            settrajLanterne("M 3975 910 L3975 910");

        }else if(ref === "Lanterne verte"){

            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


            settrajPelle("M 750 1330 L 750 1330");

        }else if(ref === "Sol de la mine" || ref === "Corde" ){
            

            // PIERRE QUI TOMBE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;


            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2} L ${xpp2+(dx-330)*4} ${ypp2}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0} ${y0+dy*4} L ${x0} ${y0+dy*4}`);
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02} L ${x02+(dx+300)*4} ${y02}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03} L ${x03+(dx+300)*4} ${y03}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp} L ${xpp+(dx+300)*4} ${ypp}`);
            }


              
            settrajLanterne("M 3975 910 L3975 910");
            settrajPelle("M 750 1330 L 750 1330");


        }else if(ref === "Pierre qui tombe"){

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            // PIOCHE

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            let t3 = trajPioche;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            let decalagey = dy*4;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;


            if (xInit === 330 && dx >= 328){
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            }else if(xInit === 330){
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
                settrajPoint(t4 + `L ${xpp2+(dx-330)*4} ${ypp2-decalagey} L ${xpp2+(dx-330)*4} ${ypp2-decalagey}`);
                settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
                settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
            }

            if (xInit === -300 && dx <= -298){
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
            }else if(xInit === -300){
                settrajWagonnet(t2 + `L ${x02+(dx+300)*4} ${y02-decalagey} L ${x02+(dx+300)*4} ${y02-decalagey}`);
                settrajPioche(t3 + `L ${x03+(dx+300)*4} ${y03-decalagey} L ${x03+(dx+300)*4} ${y03-decalagey}`);
                settrajPoint(t4 + `L ${xpp+(dx+300)*4} ${ypp-decalagey} L ${xpp+(dx+300)*4} ${ypp-decalagey}`);
                settrajLanterne(t5 + ` L 3975 ${910-decalagey} L 3975 ${910-decalagey}`);
                settrajPelle(t6 + ` L 750 ${1330-decalagey} L 750 ${1330-decalagey}`);
            }


        }else if(ref === "Wagonnet"){

            // PIERRE QUI TOMBE

            //GAUCHE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            // PIOCHE

            //DROITE
            let x033 = 4995;
            let y033 = 855;

            //GAUCHE
            let x03 = 2465;
            let y03 = 855;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;

            

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
                settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
                settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
                settrajPioche(`M ${x03} ${y03} L ${x03} ${y03}`);
            }

            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
                settrajPioche(`M ${x033} ${y033} L ${x033} ${y033}`);
                settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
                settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
            }            


        }else if(ref === "Pioche"){

            // PIERRE QUI TOMBE

            //GAUCHE
            let x0 = 2780;
            let y0 = 200;

            let t = trajPierre;

            //ROUE

            //GAUCHE
            let x04 = 2248;
            let y04 = 1497;

            let rot = ((dx+300)*180/(Math.PI*117))*Math.PI/180;

            let x = 117;
            let y = 0;
            let x00 = 2131;
            let y00 = 1497;

            let xp = x*Math.cos(-rot)+0*Math.sin(-rot);
            let yp = -x*Math.sin(-rot)+y*Math.cos(-rot);

            let xpp = xp + 2131;
            let ypp = yp + 1497;

            //DROITE
            let x044 = 4765;
            let y044 = 1497;

            let rot2 = ((dx-330)*180/(Math.PI*117))*Math.PI/180;

            //console.log(`angle : ${rot2*180/Math.PI}`);

            let xp2 = x*Math.cos(-rot2)+0*Math.sin(-rot2);
            let yp2 = -x*Math.sin(-rot2)+y*Math.cos(-rot2);

            let xpp2 = xp2 + 4648;
            let ypp2 = yp2 + 1497;

            let t4 = trajPoint;

            //LANTERNE

            let x05 = 3975;
            let y05 = 910; 

            let t5 = trajLanterne;

            //PELLE

            let x06 = 750;
            let y06 = 1330; 

            let t6 = trajPelle;

            // WAGONNET

            //GAUCHE
            let x02 = 1800;
            let y02 = 1200;

            //DROITE
            let x022 = 4330;
            let y022 = 1200;

            let t2 = trajWagonnet;

            if (xInit === -300 && dx <= -298){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajPoint(`M ${x04} ${y04} L ${x04} ${y04}`); // M 2131 1497 L 2131 1497
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            }else if(xInit === -300){
                settrajPierre(t + `L ${x0-(dx+300)*4} ${y0+dy*4} L ${x0-(dx+300)*4} ${y0+dy*4}`);
                settrajPoint(t4 + `L ${xpp} ${ypp} L ${xpp} ${ypp}`);
                settrajLanterne(t5 + ` L ${3975-(dx+300)*4} ${910} L ${3975-(dx+300)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx+300)*4} ${1330} L ${750-(dx+300)*4} ${1330}`);
                settrajWagonnet(`M ${x02} ${y02} L ${x02} ${y02}`);
            }

            if (xInit === 330 && dx >= 328){
                settrajPierre(`M ${x0} ${y0} L ${x0} ${y0}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajLanterne(`M ${x05} ${y05} L ${x05} ${y05}`);
                settrajPelle(`M ${x06} ${y06} L ${x06} ${y06}`);
                settrajPoint(`M ${x044} ${y044} L ${x044} ${y044}`);
            }else if(xInit === 330){
                settrajPierre(t + `L ${x0-(dx-330)*4} ${y0+dy*4} L ${x0-(dx-330)*4} ${y0+dy*4}`);
                settrajWagonnet(`M ${x022} ${y022} L ${x022} ${y022}`);
                settrajLanterne(t5 + ` L ${3975-(dx-330)*4} ${910} L ${3975-(dx-330)*4} ${910}`);
                settrajPelle(t6 + ` L ${750-(dx-330)*4} ${1330} L ${750-(dx-330)*4} ${1330}`);
                settrajPoint(t4 + `L ${xpp2} ${ypp2} L ${xpp2} ${ypp2}`);
                
                
            }       

        }

        dispatch(changeopt(trajPierre));
        dispatch(changepp(trajPioche));
        dispatch(changep(trajPelle));
        dispatch(changelv(trajLanterne));
        dispatch(changepr(trajPoint));
        dispatch(changew(trajWagonnet));

    }, [dx])

    /*useEffect(() => {

        if(ref === "Pierre qui tombe" && etat !== "play" && traj !== "Rien"){

            dispatch(changeTraj("Rien"));
        }
           

    }, [yslider])*/
    
    
    /*useEffect(() => {
        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
            setx(0);
            sety(0);
        }else{
            setx(decaly-sy);
            sety(decalx-sx);
        }
    }, [ref])

    useEffect(() => {
        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
            setx(0);
            sety(0);
        }else{
            setx(decaly-sy);
            sety(decalx-sx);
        }
    }, [])

    useEffect(() => {
        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
            setx(0);
            sety(0);
        }else{
            setx(decaly-sy);
            sety(decalx-sx);
        }
    }, [traj])

    useEffect(() => {
        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
            setx(0);
            sety(0);
        }else{
            setx(decaly-sy);
            sety(decalx-sx);
        }
    }, [xInit])*/
    

    return ( 
        <svg 
            width={1250}
            y={y}
            x={x}
            viewBox="0 0 5000 1917"
        >
            {/*<rect fill='#ff000025' width="100%" height="100%"/>*/}
            {
                traj === "Pierre qui tombe" && (
                    <path strokeWidth="40" stroke='#0000ff' strokeLinecap="round" d={trajPierre} fill='none'/>
                )
            }

            {
                traj === "Pointe de la pioche" && (
                    <path strokeWidth="40" stroke='#0000ff' strokeLinecap="round" d={trajPioche} fill='none'/>
                )
            }

            {
                traj === "Lanterne verte" && (
                    <path strokeWidth="40" stroke='#0000ff' strokeLinecap="round" d={trajLanterne} fill='none'/>
                )
            }

            {
                traj === "Point sur une roue" && (
                    <path strokeWidth="40" stroke='#ff0000' strokeLinecap="round" d={trajPoint} fill='none'/>
                )
            }

            {
                traj === "Wagonnet" && (
                    <path strokeWidth="40" stroke='#ff0000' strokeLinecap="round" d={trajWagonnet} fill='none'/>
                )
            }

            {
                traj === "Pelle" && (
                    <path strokeWidth="40" stroke='#ff0000' strokeLinecap="round" d={trajPelle} fill='none'/>
                )
            }
        </svg>
     );
}
 
export default Trajectoire;