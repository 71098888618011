import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../components/Checkbox';
import CheckboxNot from '../components/CheckboxNot';
import { changemode } from '../features/modetrajSlice';

const Mode = () => {

    const dispatch = useDispatch();

    const modetraj = useSelector((state) => state.modetraj.value);
    const etat = useSelector((state) => state.etat.value);

    const handleChange = () => {
        if(modetraj === 0){
            dispatch(changemode(1));
        }else{
            dispatch(changemode(0));
        }
    }


    return ( 
        <Fragment>
            {
                etat !== "play" ? (
                    <Fragment>
                    <Checkbox changeValue={handleChange} x={40} y={88} width={22} height={22}/>
        
                    <text x={300} y={106} textAnchor="middle" className="medium2" onPointerDown={handleChange} style={{ cursor : "pointer"}}>Afficher la trajectoire sous forme de points successifs</text>
        
                    </Fragment>
                ) : (
                    <Fragment>
                        <CheckboxNot x={40} y={88} width={22} height={22}/>
            
                        <text x={300} y={106} textAnchor="middle" className="medium2" style={{ cursor : "not-allowed"}}>Afficher la trajectoire sous forme de points successifs</text>
            
                    </Fragment>
                )
            }
        </Fragment>
     );
}
 
export default Mode;