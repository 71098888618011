import React, {useState,useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add,change } from '../features/dxSlice';
import { switcher } from '../features/etatSlice';
import { modifier } from '../features/xInitSlice';

const MenuPosition = ({x,y}) => {

    const dispatch = useDispatch();

    const dx = useSelector((state) => state.dx.value);
    const xInit = useSelector((state) => state.xInit.value);
    const etat = useSelector((state) => state.etat.value);

    const [positionInitWagon, setpositionInitWagon] = useState("la droite")
    
    useEffect(() => {

        if(xInit === -300){
          setpositionInitWagon("la droite");
        }else{
          setpositionInitWagon("la gauche");
        }
        
    }, [xInit])

    const handleClick = (e) => {
        if(positionInitWagon === "la gauche"){
          setpositionInitWagon("la droite");
          dispatch(modifier("gauche"));
          dispatch(change(-300));
        }else{
          setpositionInitWagon("la gauche");
          dispatch(modifier("droite"));
          dispatch(change(330));
        }
    }
    
    return ( 
        <Fragment>
            <rect x="810" y="700" rx="8" ry="8" fill="#F5F5F5" width="410" height="40" strokeWidth="4" stroke="#523438" />
            <rect x="811" y="701" rx="7" ry="7" fill="#291719" width="270" height="38" strokeWidth="0" stroke="#291719" />
            <rect x="908" y="701" fill="#291719" width="174" height="38" strokeWidth="0" stroke="#291719" />
            <text x="947" y="726" className="small" textAnchor="middle">Dans la mine le wagonnet va vers :</text>
            <rect x="1083" y="702" width="131" height="36" fill="#F5F5F5" cursor="pointer" onPointerDown={handleClick}/>
            <text x="1150" y="726" className="small2" textAnchor="middle" cursor="pointer" onPointerDown={handleClick}>{positionInitWagon}</text>
            <path strokeWidth="4" stroke='#523438' strokeLinecap="round" d="M 1205 715 L 1210 720 L 1205 725" fill='#F5F5F5' cursor="pointer" onPointerDown={handleClick}/>
            <path strokeWidth="4" stroke='#523438' strokeLinecap="round" d="M 1095 715 L 1090 720 L 1095 725" fill='#F5F5F5' cursor="pointer" onPointerDown={handleClick}/>
        </Fragment>
     );
}
 
export default MenuPosition;