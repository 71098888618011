import React,{useState} from 'react';

const IconInfo = ({changeOpen}) => {

    const [open, setopen] = useState(false);

    const handleClick =(e) => {
        changeOpen();
    }

    return ( 
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 200 200"
            width="40"
            x="1205"
            y="-360"
            onPointerDown={handleClick}
            cursor="pointer"
            >
            <circle
                cx="113.66"
                cy="112.865"
                r="71.22"
                fill="#F5F5F5"
                stroke="#523438"
                strokeWidth="12.35"
                transform="matrix(1.18436 0 0 1.21974 -34.405 -37.498)"
            ></circle>
            <text
                x="91.812"
                y="86.992"
                fill="#523438"
                fontFamily="'ArialRoundedMTBold', 'Arial Rounded MT Bold'"
                fontSize="16.692"
                transform="translate(-711.631 -598.632) scale(8.62702)"
            >
        i
      </text>
    </svg>
     );
}
 
export default IconInfo;