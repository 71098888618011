import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("etat") || "pause",
}

export const etatSlice = createSlice({
  name: 'etat',
  initialState,
  reducers: {
        switcher: (state, action) => {
          state.value  = action.payload;
          localStorage.setItem("etat", state.value);
        },
  },
})

// Action creators are generated for each case reducer function
export const { switcher } = etatSlice.actions

export default etatSlice.reducer