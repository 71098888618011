import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("ref") || "Sol de la mine",
}

export const refSlice = createSlice({
  name: 'ref',
  initialState,
  reducers: {
        changeRef : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("ref", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { changeRef } = refSlice.actions

export default refSlice.reducer