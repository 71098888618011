import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("yslider")) || 0,
}

export const ysliderSlice = createSlice({
  name: 'yslider',
  initialState,
  reducers: {
        addys: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("yslider", state.value);
        },
        changeys : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("yslider", action.payload);
        },
  },
})

const { actions, reducer } = ysliderSlice;

export const { addys, changeys } = actions;

export default reducer