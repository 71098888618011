import React, {useState,useEffect,useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add,change } from '../features/dxSlice';
import { switcher } from '../features/etatSlice';
import { modifier } from '../features/xInitSlice';
import { addy,changey } from '../features/dypSlice';
import { changedx } from '../features/decalxSlice'
import { changedy } from '../features/decalySlice'
import { changesx } from '../features/sxSlice'
import { changesy } from '../features/sySlice'
import { changexs } from '../features/xsliderSlice';
import { changeys } from '../features/ysliderSlice';

const PlayPause2 = ({x,y}) => {

    const interval = useRef(null);

    const dispatch = useDispatch();

    const dx = useSelector((state) => state.dx.value);
    const xInit = useSelector((state) => state.xInit.value);
    const etat = useSelector((state) => state.etat.value);
    const dy = useSelector((state) => state.dyp.value);
    const ref = useSelector((state) => state.ref.value);

    const decalx = useSelector((state) => state.decalx.value);
    const decaly = useSelector((state) => state.decaly.value);

    const xslider = useSelector((state) => state.xslider.value);
    const yslider = useSelector((state) => state.yslider.value);

    const traj = useSelector((state) => state.traj.value);
    const vitesse = useSelector((state) => state.vitesse.value);

    const modetraj = useSelector((state) => state.modetraj.value);

    const [deltaX, setdeltaX] = useState(4);

    useEffect(() => {
        if(vitesse === "× 1"){
            setdeltaX(4);
        }else if(vitesse === "× 1,5"){
            setdeltaX(6);
        }else{
            setdeltaX(2);
        }
        dispatch(switcher("debut"));
        initialiser();
    }, [vitesse])

    useEffect(() => {
        if(etat === "debut" || etat === "fin"){
          clearInterval(interval.current);
          interval.current = null;
        }
    }, [])

    useEffect(() => {
        if(etat === "debut" || etat === "fin"){
          clearInterval(interval.current);
          interval.current = null;
        }
      }, [etat])

    useEffect(() => {
      initialiser();
    }, [etat])
    
    useEffect(() => {
        dispatch(switcher("debut"));
        initialiser();
      }, [])

      useEffect(() => {
        dispatch(switcher("debut"));
        initialiser();
      }, [modetraj])
      
    useEffect(() => {
        dispatch(switcher("debut"));
        initialiser();
      }, [traj])

    useEffect(() => {
        if(etat !== "play"){
            //console.log(`decaly : ${decaly}`);
            if(ref === "Pierre qui tombe"){
                //dispatch(changey(yslider));
                dispatch(changedy(-yslider*1.8));
                //dispatch(changesy(yslider-dy));
            }
        }
    }, [yslider])

    useEffect(() => {
        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
            //dispatch(changesx(xslider));
        }else if(ref === "Wagonnet" || ref === "Pioche"){
            //dispatch(changesx(xslider-dx-300));
        }else if(ref === "Pierre qui tombe"){
            
        }
    }, [xslider])

      const initialiser = () => {

        if(etat === "debut"){
           
            if(xInit === -300){
                switch(ref){
                    case "Wagonnet":
                        initWagonGauche();
                    case "Pioche":
                        initWagonGauche();
                    case "Sol de la mine":
                        initSolMineGauche();
                    case "Pelle":
                        initSolMineGauche();
                    case "Corde":
                        initSolMineGauche();
                    case "Lanterne verte":
                        initSolMineGauche();
                    case "Pierre qui tombe":
                        initPierreGauche();
                }
            }else if(xInit === 330){
                switch(ref){
                    case "Wagonnet":
                        initWagonDroite();
                    case "Pioche":
                        initWagonDroite();
                    case "Sol de la mine":
                        initSolMineDroite();
                    case "Pelle":
                        initSolMineDroite();
                    case "Corde":
                        initSolMineDroite();
                    case "Lanterne verte":
                        initSolMineDroite();
                    case "Pierre qui tombe":
                        initPierreDroite();
                }   
            }
    }

    }
  
      useEffect(() => {
          
          dispatch(switcher("debut"));
          
        }, [ref])
  
        useEffect(() => {
          
          dispatch(switcher("debut"));
          
      }, [xInit])
  
      const initWagonDroite = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(330));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  
      const initWagonGauche = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(-300));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  
      const initSolMineGauche = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(-300));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  
      const initSolMineDroite = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(330));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  
      const initPierreGauche = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(-300));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  
      const initPierreDroite = () => {
          dispatch(changedx(0));
          dispatch(changedy(0));
          dispatch(change(330));
          dispatch(changey(0));
          dispatch(changexs(0));
          dispatch(changeys(0));
          dispatch(changesx(0));
          dispatch(changesy(0));
      }
  

    useEffect(() => {

        if(xInit === -300){
            if(dx >= 328){
                dispatch(switcher("fin"));
            }
        }else if(xInit === 330){
            if(dx <= -298){
                dispatch(switcher("fin"));
            }
        }

        if(ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte"){
                // pas de décalage selon les axes x et y
        }else if(ref === "Wagonnet" || ref === "Pioche"){
            if(xInit === 330){
                dispatch(changedx(-dx+330));
                if(-dx+330 !== 0 ){
                    dispatch(changexs(-decalx/1.13+608));
                }else{
                    dispatch(changexs(608));
                }
            }else{
                dispatch(changedx(-dx-300));
                if(-dx-300 !== 0){
                    dispatch(changexs(-decalx/1.13));
                }else{
                    dispatch(changexs(0));
                }
            }
            
        }else if(ref === "Pierre qui tombe"){
                dispatch(changedy(-dy));
                if(dy !== 0){
                    dispatch(changeys(-decaly/1.8));
                }else{
                    dispatch(changeys(0));
                }
        }

        

    }, [dx])
    
    useEffect(() => {
        if(xInit === -300){
            if(dx >= 328 && etat === "play"){
                dispatch(switcher("pause"));
            }
        }else if(xInit === 330){
            if(dx <= -298 && etat === "play"){
                dispatch(switcher("pause"));
            }
        }
      }, [etat])

      useEffect(() => {
        if(xInit === -300){
            if(dx >= 328 && etat === "play"){
                dispatch(switcher("pause"));
            }
        }else if(xInit === 330){
            if(dx <= -298 && etat === "play"){
                dispatch(switcher("pause"));
            }
        }
      }, [ref])


    const handleClick = (e) => {
        
        if(etat === "play"){
            dispatch(switcher("pause"))
            clearInterval(interval.current);
            interval.current = null;
        }else{
            dispatch(switcher("play"));
            if (interval.current === null) {

                var ddx = dx;
                var ddy = dy;

                var i = 0;

                var ddx2 = dx;
                var ddy2 = dy;

                interval.current = setInterval(() => {
                    i+=1;
                    if(xInit === -300){
                        if(ddx < 328){
                            dispatch(add(deltaX*7));
                            ddx = parseInt(ddx+deltaX*7);
                        }else{
                            clearInterval(interval.current);
                            interval.current = null;
                        }
                    }else if(xInit === 330){
                        if(ddx > -298){
                            dispatch(add(-deltaX*7));
                            ddx = parseInt(ddx-deltaX*7);
                        }else{
                            clearInterval(interval.current);
                            interval.current = null;
                        }
                    }

                    if(ddy < 390){
                        if(xInit === -300){
                            ddy = 0.0015*(ddx+300)*(ddx+300);
                        }else{
                            ddy = 0.0015*(ddx-330)*(ddx-330);
                        }
                        
                        if(ddy <= 403){
                            dispatch(changey(ddy));
                        }else{
                            dispatch(changey(403));
                        }
                    }

                }, 200);
            }
        }        
    }

    
    return ( 
        <svg x={x} y={y} width="50" height="50" onPointerDown={handleClick} style={{ cursor : "pointer"}}>
            <circle cx="25" cy="20" r="15" stroke="#291719" strokeWidth="0" fill="#291719" />
            {
                etat === "pause" || etat === "debut" || etat === "fin" ? (
                    <path strokeWidth="4" stroke='#F5F5F5' strokeLinecap="round" d="M 21.5 12 L 21.5 27 L 31.5 19.5 L 21.5 12 L 21.5 27" fill='#F5F5F5' />
                ) : (
                    <path strokeWidth="4" stroke='#F5F5F5' strokeLinecap="round" d="M 20 12 L 20 27 M 30 12 L 30 27" fill='#F5F5F5' />
                )
            }
            
        </svg>
     );
}
 
export default PlayPause2;