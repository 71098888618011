import React, { Fragment, useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addy,changey } from '../features/dypSlice';
import { add,change } from '../features/dxSlice';
import { changeRef } from '../features/refSlice';

const RouDeux = ({y,width}) => {

    const dy = useSelector((state) => state.dyp.value);
    const dx = useSelector((state) => state.dx.value);
    const ref = useSelector((state) => state.ref.value);

    const decalx = useSelector((state) => state.decalx.value);
    const decaly = useSelector((state) => state.decaly.value);

    const sx = useSelector((state) => state.sx.value);
    const sy = useSelector((state) => state.sy.value);

    const [rot, setrot] = useState(0)

    useEffect(() => {
        setrot(dx*180/(Math.PI*129.4));
    }, [dx])
    
    const getMatrixForRotation = (a, cx, cy) =>
        {
            var ca = Math.cos(a * Math.PI / 180);
            var sa = Math.sin(a * Math.PI / 180);
    
            var a = ca.toFixed(4);
            var b = sa.toFixed(4);
            var c = (-sa).toFixed(4);
            var d = ca.toFixed(4);
            var e = (-ca * cx + sa * cy + cx).toFixed(4);
            var f = (-sa * cx - ca * cy + cy).toFixed(4);
    
            return "matrix(" + [a,b,c,d,e,f].join(' ') + ")";
        }

    return ( 
        <svg
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 5000 1917"
        preserveAspectRatio="xMinYMin meet"
        width={width}
        y={y+decaly-sy}
        x={dx+decalx-sx}
        >
            <g transform ={getMatrixForRotation(rot,3330.4,1473.6)}>
                <path
                    fill="#48546b"
                    d="M3208.87 1483.34v-19.566h97.855l-69.193-69.215 13.829-13.832 69.193 69.219v-97.891h19.554v97.891l69.193-69.219 13.828 13.832-69.192 69.215h97.849v19.566h-97.849l69.192 69.214-13.828 13.835-69.193-69.222v97.893h-19.554v-97.893l-69.193 69.222-13.829-13.835 69.193-69.214h-97.855"
                ></path>
                <path
                    fill="#939fb8"
                    d="M3330.33 1510.11c20.083 0 36.461-16.421 36.461-36.555 0-20.132-16.378-36.555-36.461-36.555-20.077 0-36.455 16.423-36.455 36.555 0 20.134 16.378 36.555 36.455 36.555"
                ></path>
                <path
                    fill="#2e384d"
                    d="M3330.33 1491.01c9.645 0 17.503-7.839 17.503-17.45 0-9.611-7.858-17.45-17.503-17.45-9.639 0-17.497 7.839-17.497 17.45 0 9.611 7.858 17.45 17.497 17.45"
                ></path>
                <path
                    fill="#2e384d"
                    d="M3330.33 1371.2c-56.511 0-102.325 45.826-102.325 102.352s45.814 102.347 102.325 102.347c56.517 0 102.331-45.821 102.331-102.347 0-56.526-45.814-102.352-102.331-102.352zm0-27.05c71.452 0 129.378 57.937 129.378 129.402 0 71.465-57.926 129.402-129.378 129.402-71.446 0-129.372-57.937-129.372-129.402 0-71.465 57.926-129.402 129.372-129.402"
                ></path>
            </g>
        </svg>
     );
}
 
export default RouDeux;