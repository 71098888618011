import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("decaly")) || 0,
}

export const decalySlice = createSlice({
  name: 'decaly',
  initialState,
  reducers: {
        changedy : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("decaly", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { changedy } = decalySlice.actions

export default decalySlice.reducer