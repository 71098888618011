import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("sy")) || 0,
}

export const sySlice = createSlice({
  name: 'sy',
  initialState,
  reducers: {
        addsy: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("sy", state.value);
        },
        changesy : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("sy", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addsy, changesy } = sySlice.actions

export default sySlice.reducer