import React,{Component, Fragment, useState, useRef,useEffect} from 'react';

class Sliderp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rect: {
                x: 0, 
                y: 0
            }
        };
    }

    render() { 
        return ( 
            <svg x="30" y="140" width="1215" height="15" ref={(svg) => this.svg = svg}>
                <rect 
                    x={this.state.rect.x}
                    y={this.state.rect.y}
                    fill="#C0C0C0" 
                    width="1215" 
                    height="30"
                    style={{"cursor" : "not-allowed"}}
                    ref={(e) => this.svgRectElem = e}
                    />
            </svg>
        )
    }
}
 
export default Sliderp;

