import React,{Fragment,useState,useEffect} from 'react';
import { changeTraj } from '../features/trajSlice';
import { useSelector, useDispatch } from 'react-redux';

const MenuTrajectoire = () => {

    const dispatch = useDispatch();

    const traj = useSelector((state) => state.traj.value);
    const ref = useSelector((state) => state.ref.value);
    

    const [open, setopen] = useState(false)

    const [indexSelected, setindexSelected] = useState(0)

    const [listTrajectoire, setlistTrajectoire] = useState([
        "Rien",
        "Pierre qui tombe",
        "Pointe de la pioche",
        "Lanterne verte",
        "Point sur une roue",
        "Wagonnet",
        "Pelle",
    ])

    useEffect(() => {
        setindexSelected(listTrajectoire.indexOf(traj));
    }, [])


    useEffect(() => {
        setindexSelected(listTrajectoire.indexOf(traj));
    }, [traj])

    const handleClickOpen = (e) => {
        setopen(!open);
    }

    const handleClick = (e,index) => {
        setindexSelected(index);
        dispatch(changeTraj(listTrajectoire[index]));
        setopen(false);
    }

    return ( 
        <Fragment>
          <rect x="800" y="80" rx="8" ry="8" fill="#F5F5F5" width="420" height="40" strokeWidth="4" stroke="#523438" />
          <rect x="801" y="81" rx="7" ry="7" fill="#291719" width="200" height="38" strokeWidth="0" stroke="#291719" />
          <rect x="808" y="81" fill="#291719" width="196" height="38" strokeWidth="0" stroke="#291719" />
          <text x="815" y="106" className="small">Afficher la trajectoire de :</text>
          <rect x="1005" y="82" width="209" height="36" fill="#F5F5F5" cursor="pointer" onPointerDown={handleClickOpen}/>
          <text x="1105" y="106" className="small2" textAnchor="middle" cursor="pointer" onPointerDown={handleClickOpen}>{traj}</text>
          <path strokeWidth="4" stroke='#523438' strokeLinecap="round" d="M 1200 99 L 1205 104 L 1210 99" fill='#F5F5F5' cursor="pointer" onPointerDown={handleClickOpen}/>
          {
              open === true && (
                <Fragment>
                    <rect x="1000" y="130" rx="8" ry="8" fill="#F5F5F5" width="220" height="279" strokeWidth="4" stroke="#523438" />
                    
                    {
                        listTrajectoire.map( (elem,index) => {
                            return (
                                <Fragment key={`traj${index}`}>
                                    {
                                        listTrajectoire[index].toUpperCase().includes(ref.toUpperCase()) === false  ? (
                                            <rect x="1002" y={`${132+index*40}`} rx="6" ry="6" fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="35" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                        ) : (
                                            <rect x="1002" y={`${132+index*40}`} rx="6" ry="6" fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="35" strokeWidth="0" stroke="#eeeeee" cursor="not-allowed" />
                                        )
                                    }
                                    {
                                        index === 0 && (
                                            <Fragment>
                                                {
                                                    listTrajectoire[index].toUpperCase().includes(ref.toUpperCase()) === false ? (
                                                        <rect x="1002" y={`${137+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="30" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                                    ) : (
                                                        <rect x="1002" y={`${137+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="30" strokeWidth="0" stroke="#eeeeee" cursor="not-allowed" />
                                                    )
                                                }
                                            </Fragment>
                                        ) 
                                    }
                                    {
                                        index !== 0 && index !== 6 && (
                                            <Fragment>
                                                {
                                                    listTrajectoire[index].toUpperCase().includes(ref.toUpperCase()) === false ? (
                                                        <rect x="1002" y={`${132+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="36" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onMouseDown={(e) => handleClick(e,index)} onTouchStart={(e) => handleClick(e,index)}/>
                                                    ) : (
                                                        <rect x="1002" y={`${132+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="36" strokeWidth="0" stroke="#eeeeee" cursor="not-allowed" />
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        index === 6 && (
                                            <Fragment>
                                                {
                                                    listTrajectoire[index].toUpperCase().includes(ref.toUpperCase()) === false ? (
                                                        <rect x="1002" y={`${132+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="32" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onMouseDown={(e) => handleClick(e,index)}  onTouchStart={(e) => handleClick(e,index)}/>
                                                    ) : (
                                                        <rect x="1002" y={`${132+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="216" height="32" strokeWidth="0" stroke="#eeeeee" cursor="not-allowed" />
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        listTrajectoire[index].toUpperCase().includes(ref.toUpperCase()) === false ? (
                                            <text x="1110" y={`${155+index*40}`} className="small2" textAnchor="middle" cursor="pointer" onMouseDown={(e) => handleClick(e,index)} onTouchStart={(e) => handleClick(e,index)}>{elem}</text>
                                        ) : (
                                            <text x="1110" y={`${155+index*40}`} className="small2" textAnchor="middle" cursor="not-allowed" >{elem}</text>
                                        )
                                    }
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
              )
          }
        </Fragment>
     );
}
 
export default MenuTrajectoire;