import React, {useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add,change } from '../features/dxSlice';
import { switcher } from '../features/etatSlice';
import { modifier } from '../features/xInitSlice';
import { addy,changey } from '../features/dypSlice';

const Debut = ({x,y}) => {

    const dispatch = useDispatch();

    const dx = useSelector((state) => state.dx.value);
    const xInit = useSelector((state) => state.xInit.value);
    const etat = useSelector((state) => state.etat.value);
    const dy = useSelector((state) => state.dyp.value);
    const ref = useSelector((state) => state.ref.value);

    const modetraj = useSelector((state) => state.modetraj.value);

    useEffect(() => {
        if(etat !== "debut"){
            dispatch(switcher("debut"));
            if(xInit === -300){
                dispatch(change(-300));
            }else{
                dispatch(change(330));
            }
            dispatch(changey(0));
        }   
    }, [ref])

    useEffect(() => {
        dispatch(switcher("debut"));
        if(xInit === -300){
            dispatch(change(-300));
        }else{
            dispatch(change(330));
        }
        dispatch(changey(0));
    }, [modetraj])


    const handleClick = (e) => {
        
        if(etat !== "debut"){
            dispatch(switcher("debut"));
            if(xInit === -300){
                dispatch(change(-300));
            }else{
                dispatch(change(330));
            }
            dispatch(changey(0));
        }   
    }
    
    return ( 
        <svg x={x} y={y} width="50" height="50" onPointerDown={handleClick}  style={{ cursor : "pointer"}}>
            <circle cx="25" cy="20" r="15" stroke="#291719" strokeWidth="0" fill="#291719" />
            <path strokeWidth="4" stroke='#F5F5F5' strokeLinecap="round" d="M 20 19.5 L 30 27 L 30 12 L 20 19.5 L 30 27 M 17 10 L 17 29" fill='#F5F5F5' />   
        </svg>
     );
}
 
export default Debut;