import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("objw") || "",
}

export const objwSlice = createSlice({
  name: 'objw',
  initialState,
  reducers: {
        addw: (state, action) => {
          const point = action.payload;
          state.value  += point;
          localStorage.setItem("objw", state.value);
        },
        changew : (state, action) => {
          state.value = action.payload;
          localStorage.setItem("objw", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addw, changew } = objwSlice.actions

export default objwSlice.reducer