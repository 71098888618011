import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("objpt") || "",
}

export const objptSlice = createSlice({
  name: 'objpt',
  initialState,
  reducers: {
        addopt: (state, action) => {
          const point = action.payload;
          state.value  += point;
          localStorage.setItem("objpt", state.value);
        },
        changeopt : (state, action) => {
          state.value = action.payload;
          localStorage.setItem("objpt", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addopt, changeopt } = objptSlice.actions

export default objptSlice.reducer