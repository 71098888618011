import React,{Fragment,useState,useEffect} from 'react';
import { changeRef } from '../features/refSlice';
import { switcher } from '../features/etatSlice';
import { useSelector, useDispatch } from 'react-redux';

const MenuRef = () => {

    const dispatch = useDispatch();

    const ref = useSelector((state) => state.ref.value);

    const [listRef, setlistRef] = useState([
        "Sol de la mine",
        "Pelle",
        "Wagonnet",
        "Pierre qui tombe",
        "Corde",
        "Pioche",
        "Lanterne verte"
    ]);

    const [indexSelected, setindexSelected] = useState(0)

    const [open, setopen] = useState(false);

    useEffect(() => {
        setindexSelected(listRef.indexOf(ref));
    }, [])

    const handleClick = (e,index) => {
        console.log(`index : ${index}`);
        setindexSelected(index);
        dispatch(changeRef(listRef[index]));
        setopen(false);
    }

    const handleClickOpen = (e) => {
        setopen(!open);
    }

    return ( 
        <Fragment>
            <rect x="20" y="700" rx="8" ry="8" fill="#F5F5F5" width="340" height="40" strokeWidth="4" stroke="#523438" />
            <rect x="21" y="701" rx="7" ry="7" fill="#291719" width="150" height="38" strokeWidth="0" stroke="#291719" />
            <rect x="28" y="701" fill="#291719" width="146" height="38" strokeWidth="0" stroke="#291719" />
            <text x="30" y="726" className="small">Objet de référence</text>
            <rect x="175" y="702" width="179" height="36" fill="#F5F5F5" cursor="pointer" onPointerDown={handleClickOpen} />
            <text x="260" y="726" className="small2" textAnchor="middle" cursor="pointer" onPointerDown={handleClickOpen} >{ref}</text>  
            <path strokeWidth="4" stroke='#523438' strokeLinecap="round" d="M 345 715 L 350 720 L 345 725" fill='#F5F5F5' cursor="pointer" onPointerDown={handleClickOpen}/>

            {
                open === true && (
                    <Fragment>
                        <rect x="370" y="461" rx="8" ry="8" fill="#F5F5F5" width="300" height="279" strokeWidth="4" stroke="#523438" />
            
                        {
                            listRef.map( (elem,index) => {
                                return (
                                    <Fragment key={`ref${index}`}>
                                        <rect x="372" y={`${463+index*40}`} rx="6" ry="6" fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="296" height="35" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                        {
                                            index === 0 && (
                                                <rect x="372" y={`${468+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="296" height="30" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                            ) 
                                        }
                                        {
                                            index !== 0 && index !== 6 && (
                                                <rect x="372" y={`${463+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="296" height="36" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                            )
                                        }
                                        {
                                            index === 6 && (
                                                <rect x="372" y={`${463+index*40}`} fill={ indexSelected !== index ? "#eeeeee" : "#C4573F"} width="296" height="32" strokeWidth="0" stroke="#eeeeee" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}/>
                                            )
                                        }
                                        <text x="515" y={`${486+index*40}`} className="small2" textAnchor="middle" cursor="pointer" onPointerDown={(e) => handleClick(e,index)}>{elem}</text>
                                    </Fragment>
                                )
                            })
                        }
                    </Fragment>
                )
            }
            
        </Fragment>
     );
}
 
export default MenuRef;