import React, { Fragment, useEffect, useState, useRef } from 'react';
import './App.css';
import Back from './components/Back';
import Mode from './components/Mode';
import Wagon from './components/Wagon';
import RouUn from './components/RouUn';
import RouDeux from './components/RouDeux';
import PlayPause from './components/PlayPause';
import PlayPause2 from './components/PlayPause2';
import Debut from './components/Debut';
import Fin from './components/Fin';
import { useSelector, useDispatch } from 'react-redux';
import MenuPosition from './components/MenuPosition';
import PierreTombe from './components/PierreTombe';
import MenuRef from './components/MenuRef';
import MenuTrajectoire from './components/MenuTrajectoire';
import Slider from './components/Slider';
import Sliderw from './components/Slider';
import Slider2 from './components/Slider2';
import Sliderp from './components/Sliderp';
import Sliderp2 from './components/Sliderp2';

import Trajectoire from './components/Trajectoire';
import Trajectoire2 from './components/Trajectoire2';
import IconInfo from './components/IconInfo';
import Info from './components/Info';

import MenuVitesse from './components/MenuVitesse';


function App() {

  const [actifSx, setactifSx] = useState(false)
  const [actifSy, setactifSy] = useState(false)

  const [openInfo, setopenInfo] = useState(false)

  const dispatch = useDispatch();

  const sx = useSelector((state) => state.sx.value);
  const sy = useSelector((state) => state.sy.value);

  const dx = useSelector((state) => state.dx.value);

  const ref = useSelector((state) => state.ref.value);
  const xInit = useSelector((state) => state.xInit.value);

  const etat = useSelector((state) => state.etat.value);

  const xslider = useSelector((state) => state.xslider.value);
  const yslider = useSelector((state) => state.yslider.value);

  const modetraj = useSelector((state) => state.modetraj.value);
  const traj = useSelector((state) => state.traj.value);

  useEffect(() => {
    console.log(`xInit : ${xInit}`)
  }, [xInit])
  

  /*useEffect(() => {
    
    if(xInit === -300 && dx <= -298){
          setactifSx(true);
          setactifSy(true);
    }else if(xInit === 330 && dx >= 328){
          setactifSx(true);
          setactifSy(true);
    }else if(xInit === -300 && dx >= 328){
            setactifSx(true);
            setactifSy(true);
    }else if(xInit === 330 && dx <= -298){
            setactifSx(true);
            setactifSy(true);
    }else{
          setactifSx(false);
          setactifSy(false);
    }

  }, [dx])*/

  const changeOpenInfo = () => {
      setopenInfo(!openInfo);
  }
  

  return (
    <div className="svg-container">
        <svg id="container" viewBox="0 0 1250 768" preserveAspectRatio="xMinYMin meet" className="svg-content">

          <Back width={1250} x={0} y={150}/>
          <Wagon width={1250} y={150} />
          <RouUn width={1250} y={150} />
          <RouDeux width={1250} y={150} />
          <PierreTombe width={1250} x={0} y={150}/>

          {
              modetraj === 0 || traj === "Rien" ? (
                  <Trajectoire/>
              ) : (
                  <Trajectoire2/>
              )
          }
          

        <rect x="0" y="0" fill="#000000" width="1250" height="50"/>
        <text x="625" y="35" className="heavy" textAnchor="middle">Décrire un mouvement</text>

        <rect x="0" y="50" fill="#291719" width="1250" height="110"/>
        <rect x="0" y="620" fill="#291719" width="1250" height="10"/>

        <rect x="0" y="620" fill="#00000085" width="1250" height="238"/>

        <rect x="0" y="670" fill="#291719" width="1250" height="5"/>

        <rect x="0" y="160" fill="#00000085" width="25" height="460"/>
        <rect x="5" y="165" fill="#523438" width="15" height="450"/>   

        <rect x="0" y="135" fill="#00000085" width="1250" height="25"/>
        <rect x="30" y="140" fill="#523438" width="1215" height="15"/>

        <rect x="5" y="140" fill="#523438" width="15" height="15"/>

        {
          (ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte" || ref === "Pierre qui tombe") ? (
              <Sliderp/>
          ) : (
            <Fragment>
              {
                ref === "Wagonnet" && xInit === "330" ? (
                   <Sliderw dx={dx} actif={actifSx}/>
                ) : (
                   <Slider dx={dx} actif={actifSx}/>
                )
              }
            </Fragment>
          )
        }

        {
          (ref === "Sol de la mine" || ref === "Pelle" || ref === "Corde" || ref === "Lanterne verte" || ref === "Wagonnet" || ref === "Pioche") ? (
              <Sliderp2/>
          ) : (
              <Slider2 dx={dx} actif={actifSy} reff={ref} xInit={xInit}/>
          )
        }   

        <svg x="557" y="620" width="130" height="50">
            <Debut x={5} y={5}/>
            {
              modetraj === 0  ? (
                  <PlayPause x={40} y={5}/>
              ) : (
                  <PlayPause2 x={40} y={5}/>
              )
            }     
            <Fin x={75} y={5}/>
        </svg>

        <MenuVitesse x={1070} y={630}/>
        

          <MenuRef/>

          <MenuTrajectoire/>

          <Mode/>

          <MenuPosition/>

          <IconInfo changeOpen={changeOpenInfo}/>

          <Info open={openInfo} showInfo={changeOpenInfo}/>
        </svg>
      </div>
  );
}

export default App;
