import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("traj") || "Rien",
}

export const trajSlice = createSlice({
  name: 'ref',
  initialState,
  reducers: {
        changeTraj : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("traj", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { changeTraj } = trajSlice.actions

export default trajSlice.reducer