import React,{Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { addys, changeys } from '../features/ysliderSlice';

class Slider2 extends Component {
    
    constructor(props) {
        super(props);
    }

    startDrag(event) {
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.y = event.clientY;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({
            dragOffsetY : point.y - this.props.ys,
        });
        
        const mousemove = (event) => {
          event.preventDefault();
          if (event.touches) { event = event.touches[0]; }
          point.y = event.clientY;
          let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
          if(cursor.y - this.state.dragOffsetY > 0 &&  cursor.y-this.state.dragOffsetY+220 < 450){
            this.props.changeys(cursor.y - this.state.dragOffsetY);
            }
            //console.log(`décalage y : ${this.props.ys*1.8}`)
            //this.props.changeY(this.props.ys*1.8);
        };
        
        const mouseup = (event) => {
          document.removeEventListener("mousemove", mousemove);
          document.removeEventListener("mouseup", mouseup);
          document.removeEventListener("touchmove", mousemove);
          document.removeEventListener("touchend", mouseup);
        };
        
        document.addEventListener("touchmove", mousemove);      
        document.addEventListener("touchend", mouseup);
        document.addEventListener('touchleave', mouseup);
        document.addEventListener('touchcancel', mouseup);

        document.addEventListener("mousemove", mousemove);      
        document.addEventListener("mouseup", mouseup);
      }

    render() {
        return ( 
            <Fragment>
                {
                    this.props.actif === true ? (
                        <svg x="5" y="165" width="15" height="450" ref={(svg) => this.svg = svg}>
                            <rect 
                                x={0}
                                y={this.props.ys}
                                fill="#F5F5F5"
                                width="15" 
                                height="220"
                                style={{"cursor" : "pointer"}}
                                ref={(e) => this.svgRectElem = e}
                                onMouseDown={(e) => this.startDrag(e, this.svgRectElem)}
                                onTouchStart={(e) => this.startDrag(e, this.svgRectElem)}
                            />
                        </svg>
                    ) : (
                        <svg x="5" y="165" width="15" height="450" ref={(svg) => this.svg = svg}>
                            <rect 
                                x={0}
                                y={this.props.ys}
                                fill="#C0C0C0" 
                                width="15" 
                                height="220"
                                style={{"cursor" : "not-allowed"}}
                                ref={(e) => this.svgRectElem = e}
                            />
                        </svg>
                    )
                }
            </Fragment>
         );
    }
}
 
const mapStateToProps = (state) => ({
    ys: state.yslider.value,
    decaly : state.decaly.value,
})

const mapDispatchToProps = { addys, changeys };
 
export default connect(mapStateToProps, mapDispatchToProps)(Slider2);
