import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("dyp")) || 0,
}

export const dypSlice = createSlice({
  name: 'dyp',
  initialState,
  reducers: {
        addy: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("dyp", state.value);
        },
        changey : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("dyp", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addy, changey } = dypSlice.actions

export default dypSlice.reducer