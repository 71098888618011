import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("objpr") || "",
}

export const objprSlice = createSlice({
  name: 'objpr',
  initialState,
  reducers: {
        addpr : (state, action) => {
          const point = action.payload;
          state.value  += point;
          localStorage.setItem("objpr", state.value);
        },
        changepr : (state, action) => {
          state.value = action.payload;
          localStorage.setItem("objpr", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addpr, changepr } = objprSlice.actions

export default objprSlice.reducer