import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem("objlv") || "",
}

export const objlvSlice = createSlice({
  name: 'objlv',
  initialState,
  reducers: {
        addlv: (state, action) => {
          const point = action.payload;
          state.value  += point;
          localStorage.setItem("objlv", state.value);
        },
        changelv : (state, action) => {
          state.value = action.payload;
          localStorage.setItem("objlv", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addlv, changelv } = objlvSlice.actions

export default objlvSlice.reducer