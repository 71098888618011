import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("sx")) || 0,
}

export const sxSlice = createSlice({
  name: 'sx',
  initialState,
  reducers: {
        addsx: (state, action) => {
          const pasdx = action.payload;
          state.value  = state.value+pasdx;
          localStorage.setItem("sx", state.value);
        },
        changesx : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("sx", action.payload);
        },
  },
})

// Action creators are generated for each case reducer function
export const { addsx, changesx } = sxSlice.actions

export default sxSlice.reducer