import React, { Fragment } from 'react';
import CCBYNCND from '../images/88x31.png'

const Info = (props) => {

    const handleClick = (e) => {
        e.preventDefault();
        props.showInfo();
    }

    return ( 
        <Fragment>
        {
            props.open === true && (
                <svg width="500" height="400" x="375" y="182">
                <rect x="0" y="0" fill="#f5f5f5" width="100%" height="100%" rx="4" ry="4"  stroke="black" strokeWidth="16"/>
                <rect x="0" y="0" fill="#000000" width="100%" height="40" rx="4" ry="4"/>
                <rect x="0" y="20" fill="#000000" width="100%" height="20"/>
                <svg x="464" y="6" width="29" height="29" onPointerDown={handleClick} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" rx="4" ry="4" width="29" height="29" fill="black" strokeWidth="8" stroke="#990000" />
                    <line x1="8" y1="8"
                      x2="21" y2="21" 
                      stroke="#990000" 
                      strokeWidth="4"/>
                    <line x1="21" y1="8"
                        x2="8" y2="21" 
                        stroke="#990000" 
                        strokeWidth="4"/>
                </svg>
                
                
                <text x="250" y="22" textAnchor="middle" dominantBaseline="middle" fill="#f5f5f5" fontFamily="arial" fontSize="20" fontWeight="bold" >À propos de l'application web</text>
                <text x="250" y="80" textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold" >Image de la mine : iStock / Kudryavtsev Pavel</text>
                <text x="250" y="120" textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold" >Auteur de l'app web: Samuel JACQUINET</text>
                <text x="250" y="160" textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold" >Version : 1.2.5</text>
                <text x="250" y="220"  textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold">Cette œuvre est mise à disposition selon les</text>
                <text x="250" y="250"  textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold">termes de la <a fill="#03769d" target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.fr">Licence Creative Commons</a></text>
                <text x="250" y="280"  textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold"><a fill="#03769d" target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.fr">Attribution - Pas d'Utilisation Commerciale</a></text>
                <text x="250" y="310"  textAnchor="middle" dominantBaseline="middle" fill="#000000" fontFamily="arial" fontSize="20" fontWeight="bold"><a fill="#03769d" target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.fr"> - Pas de Modification 4.0 International.</a></text>
                <image x="206" y="330" href={CCBYNCND} height="31" width="88"/>
                </svg>
            )
        }
        </Fragment>
     );
}
 
export default Info;