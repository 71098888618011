import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseInt(localStorage.getItem("modetraj")) || 0,
}

export const modetrajSlice = createSlice({
  name: 'modetraj',
  initialState,
  reducers: {
        changemode : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("modetraj", action.payload);
        },
  },
})

const { actions, reducer } = modetrajSlice;

export const { changemode } = actions

export default reducer