import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem("decalx")) || 0,
}

export const decalxSlice = createSlice({
  name: 'decalx',
  initialState,
  reducers: {
        changedx : (state, action) => {
          state.value = action.payload;;
          localStorage.setItem("decalx", action.payload);
        },
  },
})

const { actions, reducer } = decalxSlice;

export const { changedx } = actions

export default reducer