import React,{Component, Fragment, useState, useRef,useEffect} from 'react';
import { connect } from 'react-redux';
import { addxs, changexs } from '../features/xsliderSlice';


class Slider extends Component {
    constructor(props) {
        super(props);
    }


    startDrag(event) {
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.x = event.clientX;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({
            dragOffsetX : point.x - this.props.xs,
        });
        
        const mousemove = (event) => {
          event.preventDefault();
          if (event.touches) { event = event.touches[0]; }
          point.x = event.clientX;
          let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
          if(cursor.x - this.state.dragOffsetX > 0 &&  cursor.x-this.state.dragOffsetX+608 < 1215){
            this.props.changexs(cursor.x - this.state.dragOffsetX);
            }
            //console.log(`décalage x : ${this.props.xs*1.13}`)
            //this.props.changeX(this.props.xs*1.13);
        };
        
        const mouseup = (event) => {
          document.removeEventListener("mousemove", mousemove);
          document.removeEventListener("mouseup", mouseup);
          document.removeEventListener("touchmove", mousemove);
          document.removeEventListener("touchend", mouseup);
        };
        
        document.addEventListener("touchmove", mousemove);      
        document.addEventListener("touchend", mouseup);
        document.addEventListener('touchleave', mouseup);
        document.addEventListener('touchcancel', mouseup);

        document.addEventListener("mousemove", mousemove);      
        document.addEventListener("mouseup", mouseup);
      }

    render() { 
        return ( 
            <Fragment>
                {
                    this.props.actif === true ? (
                        <svg x="30" y="140" width="1215" height="15" ref={(svg) => this.svg = svg}>
                            <rect 
                                x={this.props.xs}
                                y={0}
                                fill="#F5F5F5" 
                                width="608" 
                                height="30"
                                style={{"cursor" : "pointer"}}
                                ref={(e) => this.svgRectElem = e}
                                onMouseDown={(e) => this.startDrag(e, this.svgRectElem)}
                                onTouchStart={(e) => this.startDrag(e, this.svgRectElem)}
                            />
                        </svg>
                    ) : (
                        <svg x="30" y="140" width="1215" height="15" ref={(svg) => this.svg = svg}>
                            <rect 
                                x={this.props.xs}
                                y={0}
                                fill="#C0C0C0" 
                                width="608" 
                                height="30"
                                style={{"cursor" : "not-allowed"}}
                                ref={(e) => this.svgRectElem = e}
                            />
                        </svg>
                    )
                }
            </Fragment>
         );
    }
}

const mapStateToProps = (state) => ({
    xs: state.xslider.value,
    decalx : state.decalx.value,
})

const mapDispatchToProps = { addxs, changexs };
 
export default connect(mapStateToProps, mapDispatchToProps)(Slider);

