import React,{useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addy,changey } from '../features/dypSlice';
import { add,change } from '../features/dxSlice';
import { changeRef } from '../features/refSlice';

const PierreTombe = ({x,y,width}) => {
    
    const dy = useSelector((state) => state.dyp.value);
    const dx = useSelector((state) => state.dx.value);
    const ref = useSelector((state) => state.ref.value);

    const decalx = useSelector((state) => state.decalx.value);
    const decaly = useSelector((state) => state.decaly.value);

    const sx = useSelector((state) => state.sx.value);
    const sy = useSelector((state) => state.sy.value);

    return ( 
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 10000 1917"
            preserveAspectRatio="xMinYMin meet"
            width={width*2}
            y={y+dy+decaly-sy}
            x={x+decalx-sx}
        >
        
        <path
            fill="#202020"
            d="M2902.04 882.937c6.24-34.649 7.38-30.46 38.35-49.193 16.56-10.017 28.16-7.572 45.37-15.772 11.48-5.472 34.36-8.805 43.1 1.755 10.16 12.272 17.91 16.106 24.08 32.994 5.09 13.9 18.59 5.161 6.79 21.488-3.63 5.039 2.91 10.317-14.93 18.789-8.72 4.144-61.35 28.649-67.65 26.894-23.13-6.439-39.91-12.122-64.77-16.606-16.94-3.049-12.48-8.444-10.34-20.349"
            transform={`translate( ${-200} ${-685})`}
        ></path>
        </svg>
     );
}
 
export default PierreTombe;