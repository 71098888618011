import { configureStore } from '@reduxjs/toolkit'
import dxReducer from '../features/dxSlice'
import xInitReducer from '../features/xInitSlice'
import etatReducer from '../features/etatSlice'
import dypReducer from '../features/dypSlice'
import refReducer from '../features/refSlice'
import trajReducer from '../features/trajSlice'
import objptReducer from '../features/objptSlice'
import decalxReducer from '../features/decalxSlice'
import decalyReducer from '../features/decalySlice'
import sxReducer from '../features/sxSlice'
import syReducer from '../features/sySlice'
import xsliderReducer from '../features/xsliderSlice'
import ysliderReducer from '../features/ysliderSlice'
import objppReducer from '../features/objppSlice'
import objpReducer from '../features/objpSlice'
import objwReducer from '../features/objwSlice'
import objprReducer from '../features/objprSlice'
import objlvReducer from '../features/objlvSlice'
import vitesseReducer from '../features/vitesseSlice'
import modetrajReducer from '../features/modetrajSlice'

export const store = configureStore({
  reducer: {
      modetraj : modetrajReducer,
      dx : dxReducer,
      xInit : xInitReducer,
      etat : etatReducer,
      dyp : dypReducer,
      ref : refReducer,
      traj : trajReducer,
      objpt : objptReducer,
      objpp : objppReducer,
      objp : objpReducer,
      objlv : objlvReducer,
      objpr : objprReducer,
      objw : objwReducer,
      decalx : decalxReducer,
      decaly : decalyReducer,
      sx : sxReducer,
      sy : syReducer,
      xslider : xsliderReducer,
      yslider : ysliderReducer,
      vitesse : vitesseReducer,
  },
})