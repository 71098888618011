import React, {useState,useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add,change } from '../features/dxSlice';
import { switcher } from '../features/etatSlice';
import { modifier } from '../features/xInitSlice';
import { addy,changey } from '../features/dypSlice';

const Fin = ({x,y}) => {

    const dispatch = useDispatch();

    const dx = useSelector((state) => state.dx.value);
    const xInit = useSelector((state) => state.xInit.value);
    const etat = useSelector((state) => state.etat.value);
    const dy = useSelector((state) => state.dyp.value);
    const traj = useSelector((state) => state.traj.value);

    const handleClick = (e) => {
        
        if(etat !== "fin"){
            dispatch(switcher("fin"));
            if(xInit === -300){
                dispatch(change(328));
            }else{
                dispatch(change(-298));
            }
            dispatch(changey(390));
        }    
    }

    const getMatrixForRotation = (a, cx, cy) =>
        {
            var ca = Math.cos(a * Math.PI / 180);
            var sa = Math.sin(a * Math.PI / 180);
    
            var a = ca.toFixed(4);
            var b = sa.toFixed(4);
            var c = (-sa).toFixed(4);
            var d = ca.toFixed(4);
            var e = (-ca * cx + sa * cy + cx).toFixed(4);
            var f = (-sa * cx - ca * cy + cy).toFixed(4);
    
            return "matrix(" + [a,b,c,d,e,f].join(' ') + ")";
        }
    
    return ( 
        <Fragment>
            {
                traj === "Rien" ? (
                <svg x={x} y={y} width="50" height="50" onPointerDown={handleClick} style={{ cursor : "pointer"}}>
                    <circle cx="25" cy="20" r="15" stroke="#291719" strokeWidth="0" fill="#291719" />
                    <path transform={getMatrixForRotation(180,25,20)} strokeWidth="4" stroke='#F5F5F5' strokeLinecap="round" d="M 20 19.5 L 30 27 L 30 12 L 20 19.5 L 30 27 M 17 10 L 17 29" fill='#F5F5F5' />   
                </svg>
                ) : (
                <svg x={x} y={y} width="50" height="50" style={{ cursor : "not-allowed"}}>
                    <circle cx="25" cy="20" r="15" stroke="#291719" strokeWidth="0" fill="#291719" />
                    <path transform={getMatrixForRotation(180,25,20)} strokeWidth="4" stroke='#F5F5F5' strokeLinecap="round" d="M 20 19.5 L 30 27 L 30 12 L 20 19.5 L 30 27 M 17 10 L 17 29" fill='#F5F5F5' />   
                </svg>
                )
            }
        </Fragment>
     );
}
 
export default Fin;