import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: localStorage.getItem('vitesse') || "× 1",
}

export const vitesseSlice = createSlice({
  name: 'vitesse',
  initialState,
  reducers: {
    changeVitesse: (state,action) => {
      state.value = action.payload;
      localStorage.setItem("vitesse", state.value);
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeVitesse } = vitesseSlice.actions

export default vitesseSlice.reducer